import React, {Component} from "react";
import QuickLink from "./QuickLink";
import TiltEffect from "./wrappers/TiltEffect";
import {Link, withRouter} from "react-router-dom";
import SvgArrowRight from "../icons/SvgArrowRight";

class VerticalCard extends Component {
    state = {
        hovering: true
    }

    render() {
        return (
            <TiltEffect
                scale={{
                    x: .1,
                    y: .1
                }}
            >
                <div
                    onMouseEnter={() => this.setState({hovering: true})}
                    onMouseLeave={() => this.setState({hovering: false})}
                    className='flex-1 vertical-card col-jb relative' style={{
                    height: '90%',
                    padding:this.props.mobile ? 20 : 32
                }}>
                    <div>
                        <img src={this.props.item.img}
                             style={{
                                 height: this.props.mobile ? 54 : 80,
                                 marginRight: "auto",
                                 zIndex: 3,
                             }}/>
                        <div className=' col-plain' style={{alignItems: 'flex-start'}}>
                            <p className='heading3 m-0 mt-12'>
                                {this.props.item.title}
                            </p>
                            {this.props.item.new &&
                                <div className='absolute' style={{top: 20, right: 20}}>
                                    <p className='highlight body1-bold mr-a'>
                                        NEW
                                    </p>
                                </div>
                            }
                        </div>
                        <p className='title1 mt-12 mb-0 base-black-70'>
                            {this.props.item.description}
                        </p>
                    </div>
                    <div className='row-ac-jc wrap mt-32 cta' style={{gap: 24}}>
                        {this.props.item.primaryLink ?
                            // <div
                            //    className='primary-button flex-1 body1-bold'
                            //    style={{
                            //        cursor: this.props.item.title === 'Scope Labs Capital' && 'not-allowed',
                            //        opacity: this.props.item.title === 'Scope Labs Capital' && .5
                            //    }}
                            // >
                            //     Coming soon
                            //     {!!this.props.item.externalButton &&
                            //         <SvgArrowRight
                            //             style={{transform: 'rotate(-45deg)', width: 24, height: 24, marginLeft: 8}}/>
                            //     }
                            // </div>
                            <a href={'https://scopelabscapital.com/'} target={'_blank'}
                               className='primary-button flex-1 body1-bold'
                               style={{
                                   cursor: this.props.item.title === 'The Lab' && 'not-allowed',
                                   opacity: this.props.item.title === 'The Lab' && .5
                               }}
                            >
                                {this.props.item.buttonLabel}
                                {!!this.props.item.externalButton &&
                                    <SvgArrowRight style={{transform:'rotate(-45deg)', width:24, height:24, marginLeft:8}}/>
                                }
                            </a>
                            :
                            <Link to={this.props.item?.primaryRoute} disabled={!this.props.item.route}
                                  className='primary-button flex-1 body1-bold'
                            >
                                {this.props.item.buttonLabel}
                            </Link>
                        }
                        {/*<div className='row-ac' style={{*/}
                        {/*    gap: 8,*/}
                        {/*    opacity: this.props.item.title === 'The Lab' && 0,*/}
                        {/*    pointerEvents: this.props.item.title === 'The Lab' && "none"*/}
                        {/*}}>*/}
                        {/*    <Link to={this.props.item.route}*/}
                        {/*          disabled={!this.props.item.route}*/}
                        {/*          className='secondary-button body1-bold'*/}
                        {/*    >*/}
                        {/*        Learn more*/}
                        {/*    </Link>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </TiltEffect>
        )
    }
}

export default withRouter(VerticalCard)
